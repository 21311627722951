//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
	inject: ["global_data"],
	data () {
		return {
			title: 'Hallo welt',
		}
	},
	computed: {
		collapsed() {
			// obtain state from vuex store
			return this.$store.state.sidebar.collapsed;
		}
	},
	methods: {
		...mapActions({
			toggleSidebar: 'sidebar/toggle'
		})
	}
};
