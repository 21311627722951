import { render, staticRenderFns } from "./BaseDropdown.vue?vue&type=template&id=75e11082&scoped=true&"
import script from "./BaseDropdown.vue?vue&type=script&lang=js&"
export * from "./BaseDropdown.vue?vue&type=script&lang=js&"
import style0 from "./BaseDropdown.vue?vue&type=style&index=0&id=75e11082&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75e11082",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/nvme_data/andreas/git/pcbarts/lenti/lenti_api/master/frontend/components/BaseButton.vue').default})
