import * as chartConfig from '@/assets/js/chart-config.js';


export const state = () => ({
	lineChartData: {
		datasets: [],
		labels: []
	},
	barChartData: {
		datasets: [],
		labels: []
	},
	temperatureChartData: {
		datasets: [],
		labels: []
	}
});

export const actions = {
	async fetchLineChartData({ commit, state, rootState }) {
		try {
			/*const client = this.app.apolloProvider.defaultClient;
			let response = await client.query({
				query: QUERY_MASKS_COUNT,
				variables: {
					token: rootState.auth.accessToken,
					calendarInterval: '1h',
					lowerBound: 'now/d',
					upperBound: 'now/d+23h'
				}
			});
			
			let maskData = [];
			let noMaskData = [];
			let labels = [];
		
			for (let i = 0; i < response.data.mask.length; ++i) {
				let date = new Date(response.data.mask[i].timestamp * 1000);
				labels.push(date);

				maskData.push(response.data.mask[i].value);
				noMaskData.push(response.data.no_mask[i].value);
			}
			// save results in a dictionary
			commit('changeLineChartData', {
				datasets: [
					{
						label: 'Mask',
						data: maskData,
						...chartConfig.buildOptions({}, helper => helper.setLineColor('blue'))
					},
					{
						label: 'No mask',
						data: noMaskData,
						...chartConfig.buildOptions({}, helper => helper.setLineColor('red'))
					}
				],
				labels
			});
			*/
		} catch(ex) {
			console.error(ex);
		}
	},
	async fetchBarChartData({ commit, rootState }) {
		try {
			/*
			const client = this.app.apolloProvider.defaultClient;
			let response = await client.query({
				query: QUERY_MASKS_COUNT,
				variables: {
					token: rootState.auth.accessToken,
					calendarInterval: '1d',
					lowerBound: 'now/w',
					upperBound: 'now/w+6d'
				}
			});

			let maskData = [];
			let noMaskData = [];
			
			let labels = [];

			for (let i = 0; i < response.data.mask.length; ++i) {
				let date = new Date(response.data.mask[i].timestamp * 1000);
				labels.push(date.toLocaleDateString('en-GB', { weekday: 'long'}));

				maskData.push(response.data.mask[i].value);
				noMaskData.push(response.data.no_mask[i].value);
			}	

			// save results in a dictionary
			commit('changeBarChartData', {
				datasets: [
					{
						label: 'Mask',
						data: maskData,
						...chartConfig.buildOptions({}, helper => helper.setBarColor('blue'))
					},
					{
						label: 'No mask',
						data: noMaskData,
						...chartConfig.buildOptions({}, helper => helper.setBarColor('red'))
					}
				],
				labels
			});
			*/
		} catch(ex) {
			console.error(ex);
		}
	},
	async fetchTemperatureData({ commit, state, rootState }) {
		try {
			/*
			const client = this.app.apolloProvider.defaultClient;
			let response = await client.query({
				query: QUERY_TEMPERATURE,
				variables: {
					token: rootState.auth.accessToken,
					calendarInterval: '1h',
					lowerBound: 'now/d',
					upperBound: 'now/d+23h'
				}
			});

			let labels = [];
			let data = [];

			response.data.aggregation.forEach(item => {
				labels.push(new Date(item.timestamp * 1000));
				data.push(item.value)
			});
			
			commit('changeTemperatureChartData', {
				datasets: [
					{
						label: 'Ambient temperature',
						data,
						...chartConfig.buildOptions({}, helper => helper.setLineColor('red'))
					}
				],
				labels
			})
			*/
		} catch(ex) {
			console.error(ex);
		}
	}
};

export const mutations = {
	changeLineChartData(state, data) {
		state.lineChartData = data;
	},
	changeBarChartData(state, data) {
		state.barChartData = data;
	},
	changeTemperatureChartData(state, data) {
		state.temperatureChartData = data;
	}
}