export const state = () => ({
	collapsed: false
});

export const actions = {
	toggle({ commit, state }) {
		commit('setCollapsed', !state.collapsed);
	}
};

export const mutations = {
	setCollapsed(state, value) {
		state.collapsed = value;
	}
};
