

export const state = () => ({
	events: [],
	subscribed: false,
	filterOptions: [
		{ name: 'Message' },
		{ name: 'Device' },
	],
	filters: [],
	filteredIndices: [],
	lastCursor: '',
	hasNextPage: true,
	state: '',
	range: {
		upper: new Date(),
		lower: new Date('2020-11-07T19:00:00Z')
	}
});

export const getters = {
	events(state) {
		return state.filteredIndices.map(index => {
			return state.events[index];
		})
	},
	eventCount(state) {
		return state.events.length;
	}
};

export const actions = {
	// TODO: refactor strategy parameter, `once` boolean parameter?
	async fetchEvents({ commit, rootState, state }, strategy) {
		if (!state.hasNextPage) return;
		if (state.state === 'loading') return;

		if (process.env.NODE_ENV === 'development') {
			let events = [];

			for (let i = 0; i < 100; ++i) {
				events.push({
					messageId: btoa(100+i*37),
					value: `Hello World ${i}`,
					timestamp: new Date(),
					topic: 'test',
					device: {
						deviceId: 'hello',
						name: 'world'
					},
					__expanded: false
				});
			}
			commit('setPageInfo', { 
				lastCursor: '',
				hasNextPage: false
			});
			commit('setEvents', events);
			commit('updateIndices');
			commit('setState', 'loaded');
			return;
		}
		
		commit('setState', 'loading');
		try {
			/*
			const client = this.app.apolloProvider.defaultClient;
			
			while (state.hasNextPage) {
				let response = await client.query({
					query: QUERY_EVENTS,
					variables: {
						token: rootState.auth.accessToken,
						startCursor: state.lastCursor,
						dateStart: state.range.lower,
						dateEnd: state.range.upper
					}
				});
	
				// write pagination information
				commit('setPageInfo', { 
					lastCursor: response.data.messagesText.pageInfo.endCursor,
					hasNextPage: response.data.messagesText.pageInfo.hasNextPage
				});
				
				let events = response.data.messagesText.edges.map(({ node }) => {
					node.timestamp = new Date(node.timestamp * 1000);
					return { ...node, __expanded: false };
				});
				commit('setEvents', state.events.concat(events));
				commit('updateIndices');

				// page strategy: load only one page
				if (strategy === 'page') break;
			}
			*/
		} catch(ex) {
			console.error(ex);
		}
		commit('setState', 'loaded');
	},
	subscribeEvents({ commit, state }) {
		// don't subscribe again
		if (state.subscribed) return;
		try {
			/*
			const client = this.app.apolloProvider.defaultClient;
			let observer = client.subscribe({
				query: SUBSCRIBE_EVENTS
			});
			// TODO: How to determine if a subscription is canceled
			observer.subscribe({
				next(data) {
					let event = data.data.messages;
					event.timestamp = new Date(event.timestamp * 1000);
					event.__expanded = false;

					commit('setEvents', [ event, ...state.events ]);
					commit('updateIndices');
				},
				error(error) {
					console.error(error);
				},
			});
			*/
			commit('setSubscribed', true);
		} catch(ex) {
			console.error(ex);
		}
	},
	addFilter({ commit }, filter) {
		commit('addFilter', filter);
		commit('updateIndices');
	},
	resetFilter({ commit }) {
		commit('resetFilter');
		commit('updateIndices');
	},
	onFilterChange({ commit, dispatch }) {
		commit('updateIndices');
		dispatch('fetchEvents', 'filter');
	},
	setRange({ commit }, range) {
		commit('setRange', range);
	}
};

export const mutations = {
	setRange(state, range) {
		state.range = range;
	},
	setEvents(state, events) {
		state.events = events;
	},
	setSubscribed(state, value) {
		state.subscribed = value;
	},
	updateIndices(state) {
		state.filteredIndices = state.events.reduce((acc, event, index) => {
			// check if all filters match 
			// if state.filters is [] it returns true by default
			let match = state.filters.every(filter => {
				if (filter.type === 'Device') {
					return event.device.name.indexOf(filter.value) !== -1;
				}
				if (filter.type === 'Message') {
					return event.value.indexOf(filter.value) !== -1;
				}

				return true;
			});

			// add index to array
			if (match) acc.push(index);

			return acc;
		}, []);	
	},
	setState(state, value) {
		state.state = value;
	},
	setPageInfo(state, pageInfo) {
		state.lastCursor = pageInfo.lastCursor;
		state.hasNextPage = pageInfo.hasNextPage;
	},
	addFilter(state, filter) {
		state.filters.push({
			type: filter,
			value: ''
		});
	},
	resetFilter(state) {
		state.filters = [];
	}
}