//
//
//
//
//
//
//

export default {
	name: "base-button",
	props: {
		title: String,
		icon: String,
		disabled: Boolean
	}
};
