function sleep(time) {
	// asynchronous sleep function
	return new Promise((resolve) => setTimeout(resolve, time));
}

import Vue from 'vue';


export const state = () => ({
	// the raw data returned by the backend, the key of is the id of a datum
	data: {},
	// state of the data ('loaded', 'loading')
	dataState: '',
	// device specific data for the detail page
	detailData: {},
	// state of loading the details ('loaded', 'loading')
	detailState: '',
	// search query
	filter: '',
	edgefleet_options: {},
	edgefleet_options_available: false,
	edgefleet_options_data: {},
	edgefleet_options_channel: null,
	downloadable_files: {},
});

export const actions = {
	async reset_device_information({commit, state}) {
		console.log("Resetting device information")
		state.edgefleet_options_available = false
		state.edgefleet_options = {}
		state.edgefleet_options_data = {}
		state.downloadable_files = {}
	},
	// get device list from backend
	async fetchDevices({ commit, rootState }) {
		// set to loading state
		try {
			//console.log(localStorage.getItem("accessToken"));
			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("accessToken"),
				},
			};
			let response = await fetch("/api/edgekits/overview", requestOptions)
			response = await response.json();
			//console.log(response);

			let data = {};
			// save results in a dictionary
			for(let i=0; i<response.length; i++) {
				let node = response[i];
				let e_id = node.e_id.toString()
				data[e_id] = node;
			};
			commit('setData', data);
			
		} catch(e) {
			// TODO: maybe it's better to handle notifications in Vue components
			// since it belongs to the presentation logic...
			console.error(e);
			Vue.notify({
				group: 'app',
				type: 'error',
				title: 'Network Error',
				text: 'Unable to fetch device list'
			});
		}
		// reset to loaded state
		commit('setDataState', 'loaded');	
	},
	async fetchDeviceDetails({ commit, rootState, state, dispatch }, data) {
		let deviceId = data[0]
		let loading_animation = data[1]
		if(loading_animation != false) {
			console.log("fetchDeviceDetails with reloading animation")
			commit('setDetailState', 'loading');
			dispatch("reset_device_information")
		}
		try {
			let resp_code = null;
			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("accessToken"),
				},
			};
			let status = await fetch("/api/status/"+deviceId, requestOptions)
			resp_code = status.status;
			if (resp_code == 404) {
				status = await fetch("/api/status/"+deviceId+"/online", requestOptions)
				status = await status.json();
				status.status_success = false
			} else {
				status = await status.json();
				status.status_success = resp_code == 200 && status.timestamp
			}
			let e_data = await fetch("/api/edgekits/"+deviceId, requestOptions)
			e_data = await e_data.json();
			let data = {
				...status,
				...e_data,
			};
			commit('setDetailData', data);
			//state.edgefleet_options = {}
			if (rootState.auth.presences["user:edgekit_"+data.name]) {
				if (rootState.auth.presences["user:edgekit_"+data.name].metas[0].manage == "edgefleet_options") {
					console.log("Requesting manage options of edgekit user:edgekit_"+data.name)
					dispatch("join_and_handle_edgefleet_options", data)
				} else {
					console.log("presence for", "user:edgekit_"+data.name, "found but not managed by edgefleet_options")
					dispatch("reset_device_information")
				}
			} else {
				console.log("presence for", "user:edgekit_"+data.name, "not found")
				dispatch("reset_device_information")
			}
		} catch(ex) {
			Vue.notify({
				group: 'app',
				type: 'error',
				title: 'Network Error',
				text: 'Unable to fetch device details'
			});
			console.debug(ex)
		}

		await sleep(500);
		commit('setDetailState', 'loaded');
	},
	async join_and_handle_edgefleet_options({state, rootState, dispatch}, data) {
		let channel_name = "room:edgekit_"+data.name
		if(state.edgefleet_options_channel != null && state.edgefleet_options_channel.topic != channel_name) {
			console.log("Closing old edgefleet_options_channel:", state.edgefleet_options_channel)
			state.edgefleet_options_channel = null
		}
		if (window.socket == undefined) {
			console.log(rootState.auth)
			dispatch("auth/connect_websocket", {}, {root:true})
		}
		if (state.edgefleet_options_channel == null) {
			state.edgefleet_options_channel = window.socket.channel(channel_name, {user: rootState.auth.username, manage: ""})
			state.edgefleet_options_channel.on("edgefleet_options", payload => {
				console.log("got edgefleet_options:", payload)
				state.edgefleet_options = payload
				state.edgefleet_options_available = true
			})
			state.edgefleet_options_channel.on("edgefleet_options_data", payload => {
				// save the new edgefleet_options data
				console.log("got edgefleet_options_data:", payload)
				state.edgefleet_options_data = payload
			})
			state.edgefleet_options_channel.on("update_edgefleet_options_data", payload => {
				// handle updated edgelfeet_options_data by merging update object
				console.log("got update_edgefleet_options_data:", payload)
				state.edgefleet_options_data = {...state.edgefleet_options_data, ...payload}
				Vue.notify({
					group: 'app',
					type: 'success',
					title: 'Data loaded',
					text: 'Updated Edgekit options data'
				});
			})
			state.edgefleet_options_channel.on("downloadable_files", payload => {
				state.downloadable_files = payload
			})
			state.edgefleet_options_channel.on("download_file", payload => {
				console.log("Recieved file:", payload)
				const fileBlob = new Blob([payload.data], { type: 'application/octet-binary' })
				const url = URL.createObjectURL(fileBlob)
				const link = document.createElement('a')
				link.setAttribute('href', url)
				link.setAttribute('download', payload.filename)
				link.click()

				// Deallocate resources
				if (URL.revokeObjectURL) {
					URL.revokeObjectURL(url)
				}

			})
			state.edgefleet_options_channel.join()
				.receive("ok", resp => {
					console.log("Joined successfully", resp);
				})
				.receive("error", resp => {
					console.log("Unable to join", resp);
				})
		}
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+data.name].metas[0].socket, value: "get_edgefleet_options", response_event: "edgefleet_options"})
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+data.name].metas[0].socket, value: "get_edgefleet_options_data", response_event: "edgefleet_options_data"})
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+data.name].metas[0].socket, value: "get_downloadable_files", response_event: "downloadable_files"})

	},
	async update_edgefleet_options_data({state, rootState, dispatch}, changes) {
		// push updates in the edgefleet_options data
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+state.detailData.name].metas[0].socket, value: "update_edgefleet_options_data", data: JSON.stringify(changes), response_event: "update_edgefleet_options_data"})
	},
	async perform_action_on_edgefleet_options({state, rootState, dispatch}, action) {
		console.log("perform_action_on_edgefleet_options:", action)
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+state.detailData.name].metas[0].socket, value: action, data: "{}", response_event: ""})
	},
	async request_edgefleet_download_file({state, rootState, dispatch}, filename) {
		// push updates in the edgefleet_options data
		state.edgefleet_options_channel.push("direct_message", {from: rootState.auth.pid, to: rootState.auth.presences["user:edgekit_"+state.detailData.name].metas[0].socket, value: "download_file", data: JSON.stringify({filename: filename}), response_event: "download_file"})
	},
	async editDevice({ state, rootState }) {
		try {
			let data = {
				name: state.detailData.name,
				description: state.detailData.description,
			}
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("accessToken"),
				},
				body: JSON.stringify(data)
			};
			let response = await fetch("/api/edgekit/update_data/"+state.detailData.e_id, requestOptions)
			response = await response.json();

			return true;
		} catch(ex) {
			console.error(ex);
			Vue.notify({
				group: 'app',
				type: 'error',
				title: 'Network Error',
				text: 'Unable to save changes'
			});

			return false;
		}		
	},
	setFilter({ commit }, filter) {
		commit('setFilter', filter);
	},
	setDeviceName({ commit }, name) {
		commit('setDeviceName', name);
	},
	setDeviceDescription({ commit }, description) {
		commit('setDeviceDescription', description);
	}
};

/* mutations:
As the name suggests it is responsible for mutating the state of the 
store object, we can easly update Vuex state. The exclusive approach to 
actually update state in a Vuex store is by performing a mutation.
Vuex mutations are related to events: each mutation has a string type and
a handler.*/
export const mutations = {
	setFilter(state, value) {
		state.filter = value;
	},
	setData(state, data) {
		state.data = data;
	},
	setDataState(state, value) {
		state.dataState = value;
	},
	setDetailData(state, data) {
		state.detailData = data;
	},
	setDetailState(state, value) {
		state.detailState = value;
	},
	setDeviceName(state, value) {
		// NOTE: this is not reactive
		state.detailData.name = value;
	},
	setDeviceDescription(state, value) {
		// NOTE: this is not reactive
		state.detailData.description = value;
	}
};
