import Vue from 'vue';

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function(event) {
			// check if the event target is NOT the element NOR its children
			if (!(el == event.target || el.contains(event.target))) {
				// detected click event outside the element
				// call the event listener
				vnode.context[binding.expression](event);
			}
		};
		// attach the event listener
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind: function(el) {
		// detach the event listener
		document.body.removeEventListener('click', el.clickOutsideEvent);
	}
});
