//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'base-dropdown',
	props: {	
		title: {
			type: String,
			description: 'Dropdown title',
		},
		icon: {
			type: String,
			description: 'Dropdown icon'
		},
		options: {
			type: Array,
			description: 'A list of options'
		},
		autoClose: {
			type: Boolean,
			description: 'Decides whether the dropdown menu closes after an option was clicked'
		}
	},
	data() {
		return {
			open: false
		};
	},
	methods: {
		getOptionName(option) {
			if (typeof option === 'string') return option;
			return option.name;
		},
		toggleDropdown() {
			this.open = !this.open;
		},
		blurDropdown() {
			// note that this function is always called whenever the user
			// clicks on something, that isn't this element.
			if (this.open) this.open = false;
		},
		onItemClick(option) {
			if (typeof option === 'string') this.$emit('change', option);
			else if (!option.disabled) this.$emit('change', option.name);			
		},
		onMenuClick() {
			if (this.autoClose) this.open = false;
		}
	}
};
