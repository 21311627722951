//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions } from 'vuex';

export default {
	computed: {
		sidebarCollapsed() {
			return this.$store.state.sidebar.collapsed;
		},
	},
	methods: {
		...mapActions({
			logout: 'auth/logout',
			toggleSidebar: 'sidebar/toggle'
		}),
		onLogout() {
			this.logout();
			this.$router.push('/login');
		}
	}
};
