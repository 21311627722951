import Vue from 'vue';
import { sleep } from '~/assets/js/helpers';


export const state = () => ({
	test: console.log("state created..."),
	authenticated: false,
	username: '',
	accessToken: '',
	refreshToken: '',
	presences: {},
	pid: ''
});

export const actions = {
	async login({ commit, dispatch, state }, credentials) {
		try {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(credentials)
			};
			let response = await fetch("/api/login", requestOptions)
			if (response.status != 200) {
				return false
			}
			response = await response.json();
			console.log(response);
			commit('setAccessToken', response.accessToken);
			commit('setRefreshToken', response.refreshToken);
			commit('setAuthenticated', true);
			return true;
		} catch(e) {
			console.error(e);
			commit('setAccessToken', '');
			commit('setRefreshToken', '');
			commit('setAuthenticated', false);
			return false;
		}
	},
	logout({ commit }) {
		console.log("Logout");
		commit('setAccessToken', '');
		commit('setRefreshToken', '');
		commit('setAuthenticated', false);
	},
	async connect_websocket({ state }) {
		if (window.socket != undefined) {
			console.log("connect_websocket: already connected?", window.socket)
			return true
		}
		console.log("/api/ws/"+state.accessToken)
		window.socket = new Phoenix.Socket("/api/ws/"+state.accessToken);
		window.socket.connect()
		console.log("Opening socket...")
		state.username = "fastapi_user"+(Math.random()*100000).toFixed(0)
		window.presence_channel = window.socket.channel("room:presence", {user: state.username, manage: ""})

		window.presence_channel.on("presence_state", presence_state => {
			state.presences = Phoenix.Presence.syncState(state.presences, presence_state)
		})

		window.presence_channel.on("presence_diff", diff => {
			state.presences = Phoenix.Presence.syncDiff(state.presences, diff)
		})

		window.presence_channel.on("direct_message", data => {
			console.log("room:presence direct_message:", data)
		})

		window.presence_channel.on("pid", pid => {
			state.pid = pid.pid
			console.log("Got pid:", pid.pid)
		})
		window.presence_channel.join()
			.receive("ok", resp => {
				console.log("Joined room:presence successfully", resp);
			})
			.receive("error", resp => {
				console.log("Unable to join room:presence", resp)
			})
	}
};

export const mutations = {
	setAccessToken(state, token) {
		state.accessToken = token;
		localStorage.setItem("accessToken", token);
	},
	setRefreshToken(state, token) {
		state.refreshToken = token;
	},
	setAuthenticated(state, value) {
		state.authenticated = value;
	},
};