import { colors } from 'tailwindcss/defaultTheme';
import _ from 'lodash';

export const chartColors = [
	// TODO: add more colors
	colors.blue['500'],
	colors.green['500'],
	colors.yellow['500'],
	colors.red['500'],
];

export function resolveColor(color) {
	const fallback = '#fff';
	if (typeof color === 'string') {
		// fallback if color name doesn't exist
		if (!(color in colors)) return fallback;
		return colors[color]['500'];
	} else if (typeof color === 'number') {
		// fallback if index is out of range
		if (color >= chartColors.length) return fallback;
		
		return chartColors[color];
	} else if (Array.isArray(color)) {
		// resolve every entry of the array
		return color.map(resolveColor);
	}

	return fallback;
}

export function datasetOptions() {
	return {
		fill: false,
		borderColor: chartColors[0],
		backgroundColor: chartColors[0],
		pointHoverBackgroundColor: chartColors[0],
		pointBackgroundColor: chartColors[0],
		borderWidth: 2,
		borderDashOffset: 0.0,
		pointBorderColor: 'transparent',
		pointBorderWidth: 0,
		pointHoverRadius: 6,
		pointHoverBorderWidth: 0,
		pointRadius: 4
	};
}

export function buildOptions(options, callback) {
	options = options || {};

	// merge options object with default datasetOptions object
	options = _.merge(datasetOptions(), options);
	
	// return merged options object immediately if no callback function is present
	if (typeof callback !== 'function') return options;

	// optionsInterface provides several helper functions for
	// modifying the options object
	let optionsInterface = {
		options,
		setLineColor(color) {
			color = resolveColor(color);
			_.merge(this.options, {
				pointHoverBackgroundColor: color,
				pointBackgroundColor: color,
				borderColor: color
			});
			return this;
		},
		setBarColor(color) {
			color = resolveColor(color);
			_.merge(this.options, {
				backgroundColor: color,
				borderColor: color
			});
			return this;
		}
	}

	// execute callback function
	callback(optionsInterface);
	
	return options;
}


export function basicOptions() {
	return {
		maintainAspectRatio: false,
		legend: {
			display: true,

			labels: {
				fontColor: colors.gray['500'],			
			}
		},
		responsive: true,
		animation: {
			duration: 0
		},
		tooltips: {
			backgroundColor: colors.white,
			titleFontColor: colors.gray['900'],
			bodyFontColor: colors.gray['700'],
			bodySpacing: 4,
			xPadding: 12,
			mode: 'nearest',
			intersect: 0,
			position: 'nearest'
		},
		scales: {
			yAxes: [{
				gridLines: {
					display: true,
					drawBorder: false,
					color: 'rgba(29,140,248,0.1)',
					zeroLineColor: 'transparent',
				},
				ticks: {
					padding: 10,
					fontColor: colors.gray['500']
				}
			}],
			xAxes: [{
				gridLines: {
					display: true,
					drawBorder: false,
					color: 'rgba(29,140,248,0.1)',
					zeroLineColor: 'transparent',
				},
				ticks: {
					padding: 10,
					fontColor: colors.gray['500'],
					maxRotation: 0
				}
			}]
		}
	};
};

export function lineChartOptions() {
	return _.merge(basicOptions(), {
		scales: {
			yAxes: [{
				gridLines: { display: false	},		
			}]
		}
	});
};

export function barChartOptions() {
	return _.merge(basicOptions(), {
		scales: {
			xAxes: [{
				gridLines: { display: false	},		
			}]	
		}
	});
};

/*export function mergeOptions() {
	return _.merge(_.cloneDeep())
}*/
