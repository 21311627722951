//
//
//
//
//
//
//
//
//
//

import { sleep } from '@/assets/js/helpers.js';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('auth');

export default {
	name: 'default-layout',
	computed: {
		sidebarCollapsed() {
			return this.$store.state.sidebar.collapsed;
		},
	},
	provide() {
		return {
			global_data: this.global_data,
		}
	},
	data() {
		return {
			global_data: {
				links: [
					//{ to: '/', name: 'Dashboard', icon: 'tim-icons icon-chart-pie-36' },
					{ to: '/devices', name: 'Device Management', icon: 'tim-icons icon-mobile' },
					{ to: '/user', name: 'Self Management', icon: 'tim-icons icon-single-02' },
					//{ to: '/', name: 'User Management', icon: 'tim-icons icon-single-02' },
					//{ to: '/events', name: 'Events', icon: 'tim-icons icon-settings-gear-63' },
					//{ to: '/live', name: 'Live Stream', icon: 'tim-icons icon-camera-18' }
				],
			}
		}
	},
	methods: {
		...mapActions(['connect_websocket']),
	},
	async mounted() {
		console.log("Mounted: default.vue");
		//await sleep(.5);
		/*console.log(this.$store.state.auth);
		console.log(this.$store.state.auth.authenticated);
 		if (this.$store.state.auth.authenticated !== true) {
			this.$router.push('/login');
		}*/
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("accessToken"),
			},
		};
		let response = await fetch("/api/", requestOptions)
		if (response.status != 200) {
			this.$store.state.auth.authenticated = false;
			this.$router.push('/login');
		} else {
			this.connect_websocket();
		}
	},
	beforeMount() {
		console.log("beforeMount: default.vue");
		//await sleep(.5);
		console.log(this.$store.state.auth);
		console.log(this.$store.state.auth.authenticated);
 		if (this.$store.state.auth.authenticated !== true) {
			this.$router.push('/login');
		}
	}
};
