export function debounce(fn, delay) {
	var timeoutID = null;
	return function() {
		clearTimeout(timeoutID);
		var args = arguments;
		var that = this;
		timeoutID = setTimeout(function () {
			fn.apply(that, args);
		}, delay);
	}
}

export function sleep(seconds) {
	return new Promise(resolve => setTimeout(resolve, 1000 * seconds));
}

export function traverseEdges(data) {
	// TODO
}

export function readableTimeRange(seconds) {
	let percision = 2;  // number of emements to print
	let result = ""
	if (Math.floor(seconds / 86400)) {
		result = result + " " + Math.floor(seconds / 86400) + " d"
		seconds = seconds % 86400
		percision -= 1
	}
	if (Math.floor(seconds / 3600)) {
		result = result + " " + Math.floor(seconds / 3600) + " h"
		seconds = seconds % 3600
		percision -= 1
	}
	if (percision && Math.floor(seconds / 60)) {
		result = result + " " + Math.floor(seconds / 60) + " min"
		seconds = seconds % 60
		percision -= 1
	}
	if (percision && Math.floor(seconds / 1)) {
		result = result + " " + Math.floor(seconds / 1) + " sec"
		seconds = seconds % 1
		percision -= 1
	}
	return result.substring(1)
}
